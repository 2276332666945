import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Container,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import BlackBox from "../Landing/InfoTopBox";

export default function Ayuda() {
  const faqData = [
    {
      question: "¿Cómo realizo una compra en la app?",
      answer: "Primero, descarga la app y regístrate con tus datos. Luego, navega por las categorías de productos, selecciona lo que deseas y agrégalo al carrito. Finaliza tu pedido eligiendo tu método de pago y confirmando la dirección de entrega"
    },
    {
      question: "¿Qué cortes de carne están disponibles?",
      answer: "Ofrecemos una amplia variedad de cortes, incluyendo carne vacuna, cerdo, pollo y opciones premium. También contamos con productos especiales como chorizos y morcillas"
    },
    {
      question: "¿De dónde proviene la carne?",
      answer: "Trabajamos con proveedores locales de confianza que garantizan carne fresca, de calidad y de origen controlado. Además, seguimos estrictos estándares de conservación y transporte."
    },
    {
      question: "¿Puedo programar mi entrega?",
      answer: "Sí, puedes seleccionar la fecha y el horario de entrega que más te convenga al momento de realizar tu pedido."
    },
    {
      question: "¿Tienen un monto mínimo de compra?",
      answer: "Sí, el monto mínimo de compra es de $ 15000 para garantizar la entrega. Este monto puede variar según la zona de envío."
    },
    {
      question: "¿Qué métodos de pago aceptan?",
      answer: "Aceptamos tarjetas de crédito, débito, transferencias bancarias y plataformas de pago como MercadoPago, Cuenta Dni, Modo etc. También puedes consultar si tenemos opciones de pago en efectivo."
    },
    {
      question: "¿Qué hago si mi pedido llega en mal estado?",
      answer: "Si recibes un producto que no cumple con los estándares de calidad, comunícate con nosotros dentro de las primeras 24 horas para coordinar un cambio o reembolso. Por favor, conserva el producto para la evaluación."
    },
    {
      question: "¿Tienen promociones o descuentos?",
      answer: "Sí, regularmente ofrecemos promociones especiales y descuentos exclusivos."
    },
    {
      question: "¿Puedo modificar o cancelar mi pedido?",
      answer: "Puedes modificar o cancelar tu pedido hasta 2 horas antes del horario programado para la entrega. Contáctanos directamente desde la app para gestionar los cambios."
    },
    {
      question: "¿Cómo aseguran la frescura de la carne durante la entrega?",
      answer: "Utilizamos transporte refrigerado que cumple con las normativas de conservación para garantizar que la carne llegue fresca a tu puerta."
    },
    {
      question: "¿Entregan en mi zona?",
      answer: "Realizamos entregas en Zona Norte de la ciudad. Palihue, Patagonia, Aldea Romana, Palos Verdes, Bosque Alto, Solares Norte, La Reserva, La Cañada, etc"
    },
    {
      question: "¿Qué debo hacer si tengo problemas técnicos con la app?",
      answer: "Si tienes inconvenientes, puedes escribirnos al correo de soporte o utilizar el chat en línea dentro de la app. Nuestro equipo estará encantado de ayudarte."
    },
    {
      question: "¿Tienen cortes especiales por encargo?",
      answer: "Sí, ofrecemos la posibilidad de personalizar cortes o pedidos especiales. Comunícate con nosotros a través de WhatsApp"
    }
  ];

  return (
    <div style={{padding:"1rem 0", marginTop:"80px"}}>
      <Box padding={"0 1rem"} marginBottom={"2rem"}>
        <BlackBox
          titulo={"Ayuda"}
          info={"En esta sección vas a encontrar la respuesta a las dudas más frecuentes"}
        />
      </Box>
      <Container>
        <Accordion allowMultiple width="100%">
          {faqData.map((faq, index) => (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    display="flex"
                    alignItems="left"
                    justifyContent="space-between"
                    p={4}
                  >
                    <Text fontSize="md" textAlign={"left"}>{faq.question}</Text>
                    {isExpanded ? (
                      <ChevronUpIcon fontSize="24px" />
                    ) : (
                      <ChevronDownIcon fontSize="24px" />
                    )}
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text 
                      style={{
                        textAlign: 'left',
                        width: '100%',
                        display: 'flex'
                      }} 
                      color="black"
                    >
                      {faq.answer}
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </div>
  );
}