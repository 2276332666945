import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  Text,
  Link,
  useToast,
  Container,
  List,
  ListItem,
  ListIcon,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  HStack,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useBreakpointValue,
  Checkbox,
} from "@chakra-ui/react";
import {
  CheckCircleIcon,
  CheckIcon,
  EmailIcon,
  SmallCloseIcon,
  ViewIcon,
  ViewOffIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import { registerUser } from "../../Redux/Slice";
import BlackBox from "../../Landing/InfoTopBox";
import ResponsiveNav from "../../Landing/ResponsiveNav";

export default function SignupCard() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Form validation states
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isTelefonoValid, setIsTelefonoValid] = useState(true);
  const [isDireccionValid, setIsDireccionValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState({
    length: true,
    uppercase: true,
    number: true,
  });
  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [isPrivacyValid, setIsPrivacyValid] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const status = useSelector((state) => state.status);

  // Effect para manejar el retorno de la página de políticas de privacidad
  useEffect(() => {
    if (location.state?.privacyAccepted) {
      setPrivacyAccepted(true);
      setIsPrivacyValid(true);
      setShowPrivacyModal(false);

      // Restaurar los datos del formulario si existen
      if (location.state.formData) {
        const { formData } = location.state;
        setFirstName(formData.firstName || '');
        setLastName(formData.lastName || '');
        setEmail(formData.email || '');
        setDireccion(formData.direccion || '');
        setTelefono(formData.telefono || '');
        setPassword(formData.password || '');
        setConfirmPassword(formData.confirmPassword || '');
      }
    }
  }, [location]);

  // Validation functions
  const validateName = (name) => name.trim().length > 0;
  const validateDireccion = (direccion) => direccion.trim().length > 0;
  const validateTelefono = (telefono) => telefono.length > 0;
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password) => {
    if (password.length >= 3) {
      const errors = {
        length: password.length >= 8 && password.length <= 16,
        uppercase: /[A-Z]/.test(password),
        number: /\d/.test(password),
      };
      setPasswordErrors(errors);
      setShowPasswordValidation(true);
      return Object.values(errors).every(Boolean);
    } else {
      setShowPasswordValidation(false);
      return false;
    }
  };

  // Effect for real-time validation
  useEffect(() => {
    setIsFirstNameValid(firstName === "" || validateName(firstName));
    setIsLastNameValid(lastName === "" || validateName(lastName));
    setIsEmailValid(email === "" || validateEmail(email));
    setIsDireccionValid(direccion === "" || validateDireccion(direccion));
    setIsTelefonoValid(telefono === "" || validateTelefono(telefono));
    setIsPasswordValid(password === "" || validatePassword(password));
    setIsConfirmPasswordValid(
      confirmPassword === "" || password === confirmPassword
    );
  }, [firstName, lastName, email, password, confirmPassword, direccion, telefono]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!privacyAccepted) {
      setIsPrivacyValid(false);
      toast({
        title: "Políticas de privacidad",
        description: "Debes aceptar las políticas de privacidad para continuar.",
        status: "error",
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });
      return;
    }

    const isFormValid =
      validateName(firstName) &&
      validateName(lastName) &&
      validateEmail(email) &&
      validateDireccion(direccion) &&
      validateTelefono(telefono) &&
      validatePassword(password) &&
      password === confirmPassword &&
      privacyAccepted;

    if (!isFormValid) {
      toast({
        title: "Error en el formulario",
        description: "Por favor, corrija los campos marcados en rojo antes de enviar.",
        status: "error",
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
        },
      });
      return;
    }

    try {
      const result = await dispatch(
        registerUser({
          username: `${firstName} ${lastName}`.trim(),
          email,
          password,
          direccion,
          telefono,
          confirmed: false,
          poliPriv: privacyAccepted,
        })
      ).unwrap();

      if (result.error) {
        throw new Error(result.error.message || "Error desconocido en el registro");
      }

      setShowModal(true);
    } catch (error) {
      console.error("Registration error:", error);
      toast({
        title: "Error en el registro.",
        description: error.message || "Ocurrió un error durante el registro. Por favor, inténtelo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNavigateToPrivacyPolicy = () => {
    navigate('/privacy-policy', {
      state: {
        returnPath: '/signup',
        formData: {
          firstName,
          lastName,
          email,
          direccion,
          telefono,
          password,
          confirmPassword
        }
      }
    });
  };

  const PrivacyCheckbox = () => (
    <FormControl isRequired isInvalid={!isPrivacyValid}>
      <HStack spacing={2}>
        <Checkbox
          isChecked={privacyAccepted}
          onChange={(e) => {
            setPrivacyAccepted(e.target.checked);
            setIsPrivacyValid(e.target.checked);
          }}
        >
          Acepto las
        </Checkbox>
        <Link
          as="span"
          color="blue.500"
          cursor="pointer"
          onClick={handleNavigateToPrivacyPolicy}
        >
          políticas de privacidad
        </Link>
      </HStack>
      {!isPrivacyValid && (
        <FormErrorMessage>
          Debes aceptar las políticas de privacidad para continuar
        </FormErrorMessage>
      )}
    </FormControl>
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setRegisterSuccess(true);
  };

  if (registerSuccess) {
    return <Navigate to="/Login" />;
  }

  return (
    <div style={{ marginTop: "80px", paddingBottom: "6rem" }}>
      <ResponsiveNav />
      <VStack spacing={6} align="stretch" bg="white" padding={"1rem"}>
        <BlackBox
          showBackButton={true}
          info="Crea una cuenta de cliente y obtené todos nuestros beneficios."
          titulo="Registro"
        />
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch" maxW="500px" margin="auto">
            <FormControl id="firstName" isRequired isInvalid={!isFirstNameValid}>
              <FormLabel>Nombre</FormLabel>
              <Input
                style={{
                  ...styles.Input,
                  borderColor: !isFirstNameValid ? "red" : undefined,
                }}
                placeholder="Ej: Juan"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {!isFirstNameValid && (
                <FormErrorMessage>El nombre es requerido</FormErrorMessage>
              )}
            </FormControl>
  
            <FormControl id="lastName" isRequired isInvalid={!isLastNameValid}>
              <FormLabel>Apellido</FormLabel>
              <Input
                style={{
                  ...styles.Input,
                  borderColor: !isLastNameValid ? "red" : undefined,
                }}
                placeholder="Ej: Martínez"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {!isLastNameValid && (
                <FormErrorMessage>El apellido es requerido</FormErrorMessage>
              )}
            </FormControl>
  
            <FormControl id="direccion" isRequired isInvalid={!isDireccionValid}>
              <FormLabel>Direccion de recepcion</FormLabel>
              <Input
                style={{
                  ...styles.Input,
                  borderColor: !isDireccionValid ? "red" : undefined,
                }}
                placeholder="Ej: Nombre de calle 123, 2A"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
              />
              {!isDireccionValid && (
                <FormErrorMessage>La Dirección es requerida</FormErrorMessage>
              )}
            </FormControl>
  
            <FormControl id="telefono" isRequired isInvalid={!isTelefonoValid}>
              <FormLabel>Telefono</FormLabel>
              <Input
                type="number"
                style={{
                  ...styles.Input,
                  borderColor: !isTelefonoValid ? "red" : undefined,
                }}
                placeholder="Ej: 291572..."
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
              {!isTelefonoValid && (
                <FormErrorMessage>El Teléfono es requerido</FormErrorMessage>
              )}
            </FormControl>
  
            <FormControl id="email" isRequired isInvalid={!isEmailValid}>
              <FormLabel>Dirección de correo electrónico</FormLabel>
              <Input
                style={{
                  ...styles.Input,
                  borderColor: !isEmailValid ? "red" : undefined,
                }}
                type="email"
                placeholder="Ej: nombre.apellido@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!isEmailValid && (
                <FormErrorMessage>Ingrese un email válido</FormErrorMessage>
              )}
            </FormControl>
  
            <FormControl id="password" isRequired isInvalid={!isPasswordValid}>
              <FormLabel>Contraseña</FormLabel>
              <InputGroup>
                <Input
                  style={{
                    ...styles.Input,
                    borderColor: !isPasswordValid ? "red" : undefined,
                  }}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {showPasswordValidation && (
                <List spacing={2} mt={2} fontSize="sm" color="gray.600">
                  <ListItem>
                    <ListIcon
                      as={passwordErrors.length ? CheckIcon : SmallCloseIcon}
                      color={passwordErrors.length ? "green.500" : "red.500"}
                    />
                    Debe tener entre 8-16 caracteres.
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={passwordErrors.uppercase ? CheckIcon : SmallCloseIcon}
                      color={passwordErrors.uppercase ? "green.500" : "red.500"}
                    />
                    Debe tener al menos una mayúscula.
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={passwordErrors.number ? CheckIcon : SmallCloseIcon}
                      color={passwordErrors.number ? "green.500" : "red.500"}
                    />
                    Debe tener al menos un número.
                  </ListItem>
                </List>
              )}
            </FormControl>
  
            <FormControl
              id="confirmPassword"
              isRequired
              isInvalid={!isConfirmPasswordValid}
            >
              <FormLabel>Repetí la contraseña</FormLabel>
              <InputGroup>
                <Input
                  style={{
                    ...styles.Input,
                    borderColor: !isConfirmPasswordValid ? "red" : undefined,
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {!isConfirmPasswordValid && (
                <FormErrorMessage>Las contraseñas no coinciden</FormErrorMessage>
              )}
            </FormControl>
  
            <PrivacyCheckbox />
  
            <Button
              type="submit"
              colorScheme="red"
              size="lg"
              width="full"
              style={styles.button}
              isLoading={status === "loading"}
              isDisabled={
                !isFirstNameValid ||
                !isLastNameValid ||
                !isEmailValid ||
                !isPasswordValid ||
                !isConfirmPasswordValid ||
                !privacyAccepted ||
                !firstName ||
                !lastName ||
                !email ||
                !password ||
                !confirmPassword
              }
              id="registroBoton"
            >
              Registrarme
            </Button>
          </VStack>
        </form>
        <Text align="center">
          ¿Ya tenés cuenta?{" "}
          <Link as={RouterLink} to="/Login" color="red.500">
            Iniciá sesión
          </Link>
        </Text>
      </VStack>
  
      <Modal isOpen={showModal} onClose={handleCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent margin={2}>
          <ModalHeader bg="green.500" color="white" borderTopRadius="md">
            <HStack spacing={3}>
              <Icon as={CheckCircleIcon} w={6} h={6} />
              <Text>¡Registro Exitoso!</Text>
            </HStack>
          </ModalHeader>
          <ModalBody py={6}>
            <VStack spacing={4} align="stretch">
              <Text fontSize="lg" fontWeight="bold">
                ¡Bienvenido! Tu cuenta ha sido creada.
              </Text>
              <HStack>
                <Icon as={EmailIcon} color="blue.500" />
                <Text>
                  Hemos enviado un correo de confirmación a "{email}".
                </Text>
              </HStack>
              <Text>
                Por favor, revisa tu bandeja de entrada (o carpeta de spam) y
                haz clic en el enlace de confirmación para activar tu cuenta.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Entendido
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

const styles = {
  Input: {
    borderRadius: "24px",
    backgroundColor: "#F2F2F2",
    width: "100%",
  },
  button: {
    borderRadius: "24px",
    backgroundColor: "#CA0017",
    color: "white",
    width: "100%",
    padding: "12px 24px",
  },
};
