import { Button } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

const ValorsBtn = ({ valor, isSelected, onSelect }) => {
  const { user } = useSelector(state => state)
  
  return (
    <Button
      color={isSelected ? "white" : "black"}
      bg={isSelected ? "black" : "transparent"}
      borderRadius="full" 
      border="solid black 2px"
      px={6}
      py={2}
      _hover={{ bg: "gray.800", color: "white" }}
      minWidth="auto"
      whiteSpace="nowrap"
      onClick={() => onSelect(valor)}
      className="animate__animated animate__pulse"
    >
      {valor?.attributes?.nombre.split('-')[0]}
    </Button>
  );
};

export default ValorsBtn;