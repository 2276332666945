import React, { useRef, useState, useEffect } from 'react';
import { Box, Heading, Flex } from "@chakra-ui/react";
import ProductCard from './cardProd';

// Hook personalizado para calcular el número de columnas y el ancho de las tarjetas
const useColumnCount = (containerRef, minCardWidth = 140, maxCardWidth = 200, maxColumns = 8, gap = 12) => {
  const [layout, setLayout] = useState({ columnCount: 2, cardWidth: minCardWidth });

  useEffect(() => {
    const calculateLayout = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        
        // Calcula el número de columnas, asegurando un mínimo de 2
        let columnCount = Math.floor((containerWidth + gap) / (minCardWidth + gap));
        columnCount = Math.min(maxColumns, Math.max(2, columnCount));

        // Calcula el ancho real de la tarjeta
        const availableWidth = containerWidth - (columnCount - 1) * gap;
        const cardWidth = Math.min(maxCardWidth, Math.floor(availableWidth / columnCount));

        setLayout({ columnCount, cardWidth });
      }
    };

    calculateLayout();
    window.addEventListener('resize', calculateLayout);
    return () => window.removeEventListener('resize', calculateLayout);
  }, [containerRef, minCardWidth, maxCardWidth, maxColumns, gap]);

  return layout;
};

export default function CategoryComponent({ Categoria, nombreCat }) {
  const containerRef = useRef(null);
  const { columnCount, cardWidth } = useColumnCount(containerRef, 140, 200, 8, 12);

  return (
    <Box my={1} width="100%" ref={containerRef}>
      <Flex>
        <Heading
          color="black"
          fontSize="1.2rem"
          fontWeight="bold"
          textAlign="left"
          paddingLeft=".5rem"
          margin=".5rem 0"
        >
          {Categoria?.nombre}
        </Heading>
      </Flex>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${columnCount}, ${cardWidth}px)`}
        gap="12px"
        width="100%"
        justifyContent="left"
      >
        {Categoria?.articulos?.map((Prod, index) => (
          <ProductCard 
            key={Prod.id || index} 
            product={Prod} 
            Categoria={nombreCat} 
            SubCat={Categoria?.nombre}
            maxWidth={`${cardWidth}px`}
          />
        ))}
      </Box>
    </Box>
  );
}