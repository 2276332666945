import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,  // Agregamos este componente
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../Redux/Slice";
import { debounce } from 'lodash';

const AddToCartButton = ({ articulo, selectedValor, cantidad, isDisabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state);

  const calculateDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const debouncedHandleAddToCart = debounce(async () => {
    if (!user || Object.keys(user).length === 0) {
      onOpen();
      return;
    }
  
    if (!selectedValor || !selectedValor.attributes) {
      toast({
        title: "Error",
        description: "Por favor, seleccione una opción válida",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      let precioBase = Number(articulo.precioKG) || 0;
      if (articulo.DescPorciento > 0) {
        precioBase = calculateDiscountedPrice(precioBase, articulo.DescPorciento);
      }
      const precio = precioBase * (selectedValor.attributes.GrsPorcion / 1000);
  
      dispatch(addToCart({
        articleId: articulo.id,
        name: articulo.nombre,
        price: precioBase,
        quantity: Number(cantidad) || 1,
        valor: selectedValor.attributes.nombre,
        valorId: selectedValor.id,
        precioFinal: Number(precio) || 0
      }));
  
      toast({
        title: "Producto agregado",
        description: "El producto se ha agregado al carrito",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
  
    } catch (error) {
      console.error("Error al agregar al carrito:", error);
      toast({
        title: "Error",
        description: "No se pudo agregar el producto al carrito",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, 300);

  const handleLoginClick = () => {
    onClose();
    navigate("/Login");
  };

  return (
    <>
      <Button
        w="full"
        maxW="400px"
        size="lg"
        bg="#CA0017"
        borderRadius="24px"
        color="white"
        fontSize="1rem"
        zIndex={1}
        _hover={{
          transform: "translateY(2px)",
          boxShadow: "lg",
        }}
        onClick={debouncedHandleAddToCart}
        isDisabled={isDisabled}
        opacity={isDisabled ? 0.5 : 1}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        id="agregarCarrito"
      >
        Agregar al carrito
      </Button>

      <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        isCentered
        closeOnOverlayClick={true}  // Permitir cerrar al hacer clic fuera
        closeOnEsc={true}           // Permitir cerrar con la tecla Esc
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalCloseButton />      {/* Botón X para cerrar */}
          <ModalHeader>Iniciar sesión requerida</ModalHeader>
          <ModalBody>
            Para agregar productos al carrito o iniciar el proceso de compras,
            es necesario estar logueado.
          </ModalBody>
          <ModalFooter>
            <Button
              w="full"
              bg="#CA0017"
              color="white"
              borderRadius="24px"
              h="45px"
              onClick={handleLoginClick}
              _hover={{
                bg: "#b30014",
              }}
            >
              Iniciar sesión
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddToCartButton;