import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGoogleCallback, updatePrivacyAcceptance } from '../../Redux/Slice';
import {
  Box,
  VStack,
  Spinner,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Link,
  Checkbox,
  useToast,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { ExternalLinkIcon, WarningIcon } from '@chakra-ui/icons';

const GoogleCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [isProcessing, setIsProcessing] = useState(true);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      handleGoogleLogin(code);
    } else {
      setIsProcessing(false);
      navigate('/Login', { 
        state: { error: 'No se recibió el código de autenticación de Google' }
      });
    }
  }, [location]);

  const handleGoogleLogin = async (code) => {
    try {
      console.log('Iniciando login con código:', code);
      
      const result = await dispatch(handleGoogleCallback({
        code: code,
        acceptPrivacy: false
      })).unwrap();
      
      console.log('Resultado del login:', result);

      if (!result.user.poliPriv) {
        setCurrentUser(result.user);
        setShowPrivacyModal(true);
        setIsProcessing(false);
        return;
      }

      checkUserInfo(result.user);
    } catch (error) {
      console.error('Error completo durante el login:', error);
      setIsProcessing(false);
      
      let errorMessage = 'No se pudo completar el inicio de sesión con Google';
      if (error.response?.data?.error?.message) {
        errorMessage = error.response.data.error.message;
      }
      
      toast({
        title: "Error de autenticación",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      
      navigate('/Login', { state: { error: errorMessage } });
    }
  };

  const handlePrivacyAcceptance = async () => {
    if (!privacyAccepted || !currentUser) {
      toast({
        title: "Políticas de privacidad",
        description: "Debes aceptar las políticas de privacidad para continuar",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsProcessing(true);
    try {
      // Actualizar directamente la aceptación de privacidad
      await dispatch(updatePrivacyAcceptance({
        userId: currentUser.id,
        accepted: true
      })).unwrap();
      
      // Una vez actualizada la privacidad, continuar con el flujo
      checkUserInfo(currentUser);
    } catch (error) {
      console.error('Error al actualizar políticas:', error);
      toast({
        title: "Error",
        description: "No se pudo completar el proceso. Por favor, inténtalo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
      setShowPrivacyModal(false);
    }
  };

  const checkUserInfo = (user) => {
    if (!user.username || !user.email || !user.direccion || !user.telefono) {
      navigate('/PersonalData');
    } else {
      navigate('/');
    }
  };

  if (isProcessing && !showPrivacyModal) {
    return (
      <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
        <VStack spacing={4}>
          <Spinner size="xl" color="blue.500" />
          <Text>Procesando inicio de sesión con Google...</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <>
      <Modal 
        isOpen={showPrivacyModal} 
        onClose={() => {}}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack spacing={2}>
              <Icon as={WarningIcon} color="orange.500" />
              <Text>Políticas de Privacidad</Text>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text>
                Para continuar usando nuestros servicios, es necesario que aceptes nuestras políticas de privacidad.
              </Text>
              <Link
                href="https://www.deternera.com/privacy-policy"
                isExternal
                color="blue.500"
                display="flex"
                alignItems="center"
              >
                Ver políticas de privacidad <ExternalLinkIcon mx="2px" />
              </Link>
              <Checkbox
                isChecked={privacyAccepted}
                onChange={(e) => setPrivacyAccepted(e.target.checked)}
              >
                He leído y acepto las políticas de privacidad
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handlePrivacyAcceptance}
              isLoading={isProcessing}
              isDisabled={!privacyAccepted}
            >
              Aceptar y Continuar
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                navigate('/Login');
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GoogleCallback;