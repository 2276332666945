import React from "react";
import Morton from "../assets/LogoMorton.jpg"
import { Flex, Image, Text } from "@chakra-ui/react";


export default function MortonFooter (){
     
  const emailSubject = "Contacto desde Deternera.com";
  const emailBody = `Estimado equipo de Morton Desarrollos,

Me comunico con ustedes ya que encontré su contacto a través de Deternera.com.
Me gustaría poder coordinar una reunión para discutir una propuesta comercial.

Quedo a la espera de su respuesta.

Saludos cordiales.`;

  const mailtoLink = `mailto:mortondesarrollos@gmail.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

    return(
        <Flex
        // justify="space-arrownd"
        align="center"
        color="gray.600"
        fontSize="sm"
        flexDir="column"
        
      >
        <Text>© {new Date().getFullYear()} Todos los derechos reservados.</Text>
        <Flex align="center" gap={2}>
          <Text>Desarrollado por      </Text>
          <a 
            href={mailtoLink}
            target="_blank"
            style={{ 
              cursor: 'pointer',
              transition: 'transform 0.2s ease-in-out',
        
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
            
          >
            <Image src={Morton} alt="Morton" width="44px" />
          </a>
        </Flex>
      </Flex>
    )
}