import React, { useEffect, useState } from "react";
import { Box, Heading, Text, VStack, Flex, Image } from "@chakra-ui/react";
import Morton from "../assets/LogoMorton.jpg"
import MortonFooter from "./Morton"

const InfoBottomBox = ({ titulo1, info1, titulo2, info2 }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box width="100%">
      <Box
        bg="#2E2E2E"
        color="white"
        p={4}
        borderRadius="24px"
        width={"100%"}
        style={{
          marginBottom: isMobile ? "1rem" : "0",
        }}
      >
        <Flex
          style={{
            flexDirection: isMobile ? "column" : "row",
          }}
          align="flex-start"
          spacing={2}
          padding="1rem"
          justifyContent={"space-around"}
        >
          <VStack alignItems={"flex-start"}>
            <Heading as="h1" fontSize="24px" fontWeight="bold" textAlign="left">
              {titulo1}
            </Heading>
            <Text fontSize="1rem" textAlign="left">
              {info1}
            </Text>
          </VStack>
          <VStack alignItems={"flex-start"}>
            <Heading as="h1" fontSize="24px" fontWeight="bold" textAlign="left">
              {titulo2}
            </Heading>
            <Text fontSize="1rem" textAlign="left">
              <a
                href={`tel:${info2}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {info2}
              </a>
            </Text>
          </VStack>
        </Flex>
      </Box>
      
      <MortonFooter/>
    </Box>
  );
};

export default InfoBottomBox;