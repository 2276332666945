import React, { useState } from "react";
import {
  Box,
  Image,
  Flex,
  VStack,
  IconButton,
  Text,
  AspectRatio,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import genericImg from "../assets/DetPord.png";

const URL_BASE = process.env.REACT_APP_API_BASE;

export default function ImageCarousel({ articulo, getInsigniaImage }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (articulo?.img?.data && articulo.img.data.length > 0) {
      setCurrentImageIndex((prev) =>
        prev === articulo.img.data.length - 1 ? 0 : prev + 1
      );
    }
  };

  const prevImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (articulo?.img?.data && articulo.img.data.length > 0) {
      setCurrentImageIndex((prev) =>
        prev === 0 ? articulo.img.data.length - 1 : prev - 1
      );
    }
  };

  const getCurrentImageUrl = () => {
    try {
      if (articulo?.img?.data && 
          articulo.img.data.length > 0 && 
          articulo.img.data[currentImageIndex]?.attributes?.url) {
        const imageUrl = articulo.img.data[currentImageIndex].attributes.url;
        if (imageUrl.startsWith('http')) {
          return imageUrl;
        }
      }
      return genericImg;
    } catch (error) {
      console.error('Error al obtener URL de imagen:', error);
      return genericImg;
    }
  };

  const hasMultipleImages = articulo?.img?.data && articulo.img.data.length > 1;

  return (
    <VStack spacing={4} width="100%">
      <AspectRatio width="100%" ratio={1}>
        <Box position="relative" width="100%" height="100%">
          <Image
            rounded="12px"
            alt={`${articulo?.nombre || "Producto"} imagen ${currentImageIndex + 1}`}
            src={getCurrentImageUrl()}
            loading="lazy"
            objectFit="cover"
            width="100%"
            height="100%"
          />

          {hasMultipleImages && (
            <>
              <IconButton
                icon={<ChevronLeft size={24} />}
                onClick={prevImage}
                position="absolute"
                left="4"
                top="50%"
                transform="translateY(-50%)"
                size="sm"
                bg="white"
                opacity="0.8"
                _hover={{ opacity: 1 }}
                zIndex="2"
                rounded="full"
              />
              <IconButton
                icon={<ChevronRight size={24} />}
                onClick={nextImage}
                position="absolute"
                right="4"
                top="50%"
                transform="translateY(-50%)"
                size="sm"
                bg="white"
                opacity="0.8"
                _hover={{ opacity: 1 }}
                zIndex="2"
                rounded="full"
              />
            </>
          )}

          <VStack
            position="absolute"
            top="15px"
            left="15px"
            spacing={2}
            alignItems="flex-start"
            zIndex="1"
          >
            {articulo?.insignias?.data?.map((insignia) => {
              const insigniaImage = getInsigniaImage(insignia.id);
              return insigniaImage ? (
                <Image
                  key={insignia.id}
                  src={insigniaImage}
                  alt={`Insignia ${insignia.id}`}
                  width="44px"
                  height="44px"
                />
              ) : null;
            })}
          </VStack>

          {articulo?.DescPorciento != null && articulo?.DescPorciento !== 0 && (
            <Box
              position="absolute"
              top="15px"
              right="15px"
              bg="#318215"
              color="white"
              px="2"
              py="1"
              borderRadius="12px"
              fontSize="sm"
              fontWeight="bold"
              zIndex="1"
            >
              -{articulo.DescPorciento}%
            </Box>
          )}

          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            padding="24px 0 20px 24px"
            background="linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%)"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            borderRadius="12px"
            zIndex="1"
          >
            <Text color="white" fontSize="2rem" textAlign="left" className="titles">
              {articulo?.nombre}
            </Text>
          </Box>
        </Box>
      </AspectRatio>

      {/* {hasMultipleImages && (
        <Flex gap={2} width="100%" overflowX="auto" py={2}>
          {articulo.img.data.map((image, index) => (
            <AspectRatio key={index} width="60px" ratio={1}>
              <Box
                cursor="pointer"
                opacity={currentImageIndex === index ? 1 : 0.6}
                transition="opacity 0.2s"
                _hover={{ opacity: 0.8 }}
                onClick={() => setCurrentImageIndex(index)}
              >
                <Image
                  src={image.attributes.url.startsWith('http') ? image.attributes.url : genericImg}
                  alt={`Thumbnail ${index + 1}`}
                  objectFit="cover"
                  width="100%"
                  height="100%"
                  borderRadius="6px"
                  border={currentImageIndex === index ? "2px solid black" : "none"}
                />
              </Box>
            </AspectRatio>
          ))}
        </Flex>
      )} */}
    </VStack>
  );
}