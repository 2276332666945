import React from 'react';
import { Box, Button, Text, Heading, Checkbox, VStack, Link, Icon, HStack } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import ResponsiveNav from "../../Landing/ResponsiveNav";
import { updatePrivacyAcceptance } from '../../Redux/Slice';

const PrivacyPolicy = () => {
  const [isChecked, setIsChecked] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const returnPath = location.state?.returnPath || '/signup';
  const isFromGoogle = location.state?.isFromGoogle || false;

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleConfirm = () => {
    if (isChecked) {
      dispatch(updatePrivacyAcceptance(true));
      
      if (isFromGoogle) {
        navigate('/PersonalData', { 
          state: { 
            privacyAccepted: true,
            isFromGoogle: true 
          }
        });
      } else {
        navigate(returnPath, { 
          state: { 
            privacyAccepted: true 
          }
        });
      }
    } else {
      alert("Por favor, confirma que has leído la política de privacidad.");
    }
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <ResponsiveNav />
      <Box
        p={6}
        borderWidth={1}
        borderRadius="md"
        boxShadow="md"
        maxW="3xl"
        mx="auto"
        mt={10}
        mb={10}
        bg="white"
      >
        <VStack spacing={6} align="stretch" textAlign={"left"}>
          <HStack spacing={3}>
            <Icon as={InfoIcon} w={6} h={6} color="blue.500" />
            <Heading fontSize="2xl">Política de Privacidad</Heading>
          </HStack>

          <Text>
            En <strong>DeTernera</strong>, respetamos tu privacidad y estamos comprometidos a proteger los datos personales que nos proporcionas.
            Esta política de privacidad explica cómo recopilamos, usamos y protegemos tu información cuando utilizas nuestra aplicación.
          </Text>

          <VStack align="start" textAlign={"left"} spacing={4} >
            <Box>
              <Heading size="md" mb={2}>1. Información que Recopilamos</Heading>
              <Text>
                • Datos personales: nombre, dirección, correo electrónico, teléfono<br />
                • Información de pedidos y preferencias de compra<br />
                • Datos de uso de la aplicación
              </Text>
            </Box>

            <Box>
              <Heading size="md" mb={2}>2. Uso de la Información</Heading>
              <Text>
                • Procesar y entregar tus pedidos<br />
                • Mejorar tu experiencia de usuario<br />
                • Enviarte comunicaciones importantes sobre tus pedidos<br />
                • Personalizar nuestros servicios según tus preferencias
              </Text>
            </Box>

            <Box>
              <Heading size="md" mb={2}>3. Protección de Datos</Heading>
              <Text>
                • Implementamos medidas de seguridad robustas<br />
                • Encriptamos la información sensible<br />
                • Acceso restringido a datos personales<br />
                • Actualizaciones regulares de seguridad
              </Text>
            </Box>

            <Box>
              <Heading size="md" mb={2}>4. Tus Derechos</Heading>
              <Text>
                • Acceder a tus datos personales<br />
                • Corregir información inexacta<br />
                • Solicitar la eliminación de tus datos<br />
                • Oponerte al procesamiento de tus datos
              </Text>
            </Box>

            <Box>
              <Heading size="md" mb={2}>5. Contacto</Heading>
              <Text>
                Para cualquier consulta sobre privacidad, contáctanos en:<br />
                Email: <Link href="mailto:info@deternera.com" color="blue.500">info@deternera.com</Link><br />
                Teléfono: <Link href="tel:+5429142280461" color="blue.500">+54 291 572-9501</Link>
              </Text>
            </Box>
          </VStack>

          <Box mt={6} p={4} bg="gray.50" borderRadius="md">
            <Checkbox 
              size="lg"
              colorScheme="blue"
              isChecked={isChecked} 
              onChange={handleCheckboxChange}
            >
              <Text fontSize="md" fontWeight="medium">
                He leído y acepto la política de privacidad
              </Text>
            </Checkbox>

            <Button
              mt={4}
              colorScheme="blue"
              size="lg"
              width="full"
              onClick={handleConfirm}
              isDisabled={!isChecked}
            >
              Confirmar y Continuar
            </Button>
          </Box>
        </VStack>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;