import { configureStore } from '@reduxjs/toolkit';
import counterReducer, { logoutUser } from './Slice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const INACTIVITY_TIMEOUT = 30 * 60 * 1000;  // 30 minutos

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    "token",
    "role",
    'articulos',
    'valores',
    'categories',
    'pedidoActual',
    'carrito'
  ],
};

const persistedReducer = persistReducer(persistConfig, counterReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/PURGE'
        ],
      },
    }),
});

export const persistor = persistStore(store);

let appState = {
  isClosing: false,
  lastActivity: Date.now(),
  inactivityTimeout: null,
  visibilityTimeout: null,
  isRefreshing: false
};

const clearStorageAndLogout = async () => {
  try {
    if (!appState.isRefreshing) {
      await store.dispatch(logoutUser());
      localStorage.clear();
      sessionStorage.clear();
    }
  } catch (error) {
    console.error('Error during cleanup:', error);
  }
};

const resetInactivityTimer = () => {
  if (appState.inactivityTimeout) {
    clearTimeout(appState.inactivityTimeout);
  }
  
  appState.lastActivity = Date.now();
  appState.inactivityTimeout = setTimeout(() => {
    const user = store.getState().user;
    if (user && Object.keys(user).length > 0) {
      clearStorageAndLogout();
    }
  }, INACTIVITY_TIMEOUT);
};

if (typeof window !== 'undefined') {
  // Detectar refresh
  window.addEventListener('unload', () => {
    const isRefresh = performance.navigation.type === 1 || 
                     document.visibilityState === "visible";
    appState.isRefreshing = isRefresh;
  });

  // Manejar cierre de ventana
  window.addEventListener('beforeunload', async (event) => {
    const isRefresh = performance.navigation.type === 1 || 
                     document.visibilityState === "visible";
    
    if (!isRefresh && !appState.isClosing) {
      appState.isClosing = true;
      await clearStorageAndLogout();
    }
  });

  // Eventos de actividad
  const activityEvents = [
    'mousedown',
    'mousemove',
    'keydown',
    'scroll',
    'touchstart',
    'click'
  ];

  activityEvents.forEach(eventName => {
    window.addEventListener(eventName, () => {
      appState.lastActivity = Date.now();
      resetInactivityTimer();
    }, { passive: true });
  });
}

export default store;