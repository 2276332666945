import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  ChakraProvider,
  Button,
  Spinner,
  HStack,
  IconButton,
  Alert,
  AlertIcon,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import {
  RotateCcw,
  ClipboardList,
  CheckCircle,
  XCircle,
  Loader,
  AlertCircle,
  Clock,
  Pause,
  Play,
} from "lucide-react";
import BlackBox from "../Landing/InfoTopBox";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerHistorialPedidos,
  obtenerTodosPedidos,
  logoutUser,
} from "../Redux/Slice";
import OrderBox from "./OrderBox";

const formatTimeSince = (date) => {
  const diff = Math.floor((new Date() - date) / 1000); // diferencia en segundos

  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = diff % 60;

  // Función helper para añadir ceros a la izquierda
  const padNumber = (num) => num.toString().padStart(2, "0");

  if (hours > 0) {
    return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
  } else {
    return `${padNumber(minutes)}:${padNumber(seconds)}`;
  }
};

const LoadingState = () => (
  <Flex justify="center" align="center" width="100%" height="200px">
    <VStack spacing={4}>
      <Spinner size="xl" color="blue.500" />
      <Text>Cargando pedidos...</Text>
    </VStack>
  </Flex>
);

const ErrorState = ({ message, onRetry, onLogout }) => (
  <Alert status="error" borderRadius="md">
    <AlertIcon />
    <Flex justify="space-between" align="center" width="100%">
      <Text>{message}</Text>
      <HStack spacing={2}>
        <Button onClick={onRetry} size="sm" colorScheme="red">
          Reintentar
        </Button>
        {onLogout && (
          <Button
            onClick={onLogout}
            size="sm"
            colorScheme="red"
            variant="outline"
          >
            Iniciar Sesión
          </Button>
        )}
      </HStack>
    </Flex>
  </Alert>
);

const NoOrdersState = () => (
  <Flex direction="column" align="center" justify="center" py={8}>
    <ClipboardList size={48} className="text-gray-400" />
    <Text mt={2} fontWeight="medium">
      No hay pedidos
    </Text>
    <Text mt={1} color="gray.500" fontSize="sm">
      No se encontraron pedidos para mostrar.
    </Text>
  </Flex>
);

export default function Pedidos() {
  const dispatch = useDispatch();
  const toast = useToast();
  const { historial, user, status } = useSelector((state) => state);
  const [selectedStatus, setSelectedStatus] = useState("pendiente");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
  const [timeSinceUpdate, setTimeSinceUpdate] = useState("");
  const [pollingInterval, setPollingInterval] = useState(2 * 60 * 1000);

  useEffect(() => {
    // Actualizar inmediatamente
    setTimeSinceUpdate(formatTimeSince(lastUpdateTime));

    // Configurar el intervalo para actualizar cada 15 segundos
    const intervalId = setInterval(() => {
      setTimeSinceUpdate(formatTimeSince(lastUpdateTime));
    }, 15000);

    return () => clearInterval(intervalId);
  }, [lastUpdateTime]);

  const handleAuthError = useCallback(async () => {
    await dispatch(logoutUser());
    window.location.href = "/login";
    toast({
      title: "Sesión expirada",
      description: "Por favor, inicia sesión nuevamente",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }, [dispatch, toast]);

  const fetchPedidos = useCallback(async () => {
    try {
      setIsRefreshing(true);
      setLoadingError(null);

      if (user?.role === "Admin") {
        await dispatch(obtenerTodosPedidos()).unwrap();
      } else {
        await dispatch(obtenerHistorialPedidos()).unwrap();
      }
      setLastUpdateTime(new Date());
    } catch (err) {
      if (err.status === 401 || err.message?.includes("credentials")) {
        handleAuthError();
      } else {
        setLoadingError(err.message || "Error al cargar los pedidos");
      }
    } finally {
      setIsRefreshing(false);
      setIsInitialLoad(false);
    }
  }, [dispatch, user?.role, handleAuthError]);

  // Agregar esta función para controlar el polling
  const togglePolling = useCallback(() => {
    setPollingEnabled((prev) => !prev);
    toast({
      title: pollingEnabled
        ? "Actualización automática desactivada"
        : "Actualización automática activada",
      description: pollingEnabled
        ? "Los pedidos ya no se actualizarán automáticamente"
        : "Los pedidos se actualizarán cada 2 minutos",
      status: pollingEnabled ? "info" : "success",
      duration: 3000,
      isClosable: true,
    });
  }, [pollingEnabled, toast]);

  useEffect(() => {
    // Carga inicial
    fetchPedidos();

    let intervalId;
    if (pollingEnabled && user?.role === "Admin") {
      // Configura el intervalo
      intervalId = setInterval(fetchPedidos, pollingInterval);

      // Log para debugging
      console.log(
        `Polling configurado: se actualizará cada ${
          pollingInterval / 1000
        } segundos`
      );
    }

    // Limpieza
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        console.log("Polling detenido");
      }
    };
  }, [fetchPedidos, user?.role, pollingEnabled, pollingInterval]);

  const organizedPedidos = useMemo(() => {
    const result = {
      xxxx: [],
      pendiente: [],
      en_proceso: [],
      finalizado: [],
      cancelado: [],
    };

    if (Array.isArray(historial)) {
      historial.forEach((pedido) => {
        if (pedido?.attributes?.estado) {
          const estado = pedido.attributes.estado;
          result[estado]?.push(pedido) || result.xxxx.push(pedido);
        }
      });

      Object.keys(result).forEach((key) => {
        result[key].sort(
          (a, b) =>
            new Date(b.attributes?.createdAt || 0) -
            new Date(a.attributes?.createdAt || 0)
        );
      });
    }

    return result;
  }, [historial]);

  const filteredAndSortedPedidos = useMemo(() => {
    return selectedStatus === "todos"
      ? Object.values(organizedPedidos).flat()
      : organizedPedidos[selectedStatus] || [];
  }, [organizedPedidos, selectedStatus]);

  const handleRefresh = () => {
    fetchPedidos();
  };

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (isInitialLoad) {
    return <LoadingState />;
  }

  return (
    <ChakraProvider>
      <Box position="relative" minHeight="100vh" pb="60px">
        <VStack spacing={4} p={4}>
          <BlackBox
            titulo="Pedidos"
            info="Seleccioná cualquiera de los pedidos para conocer el detalle del mismo."
            showInicioButton={true}
          />
          {/* 
          <BlackBox
            titulo="Panel de Administración de Pedidos"
            info={
              user?.role === "Admin"
                ? "Gestiona los pedidos pendientes, marca como en proceso o finalizados, y mantén un seguimiento del estado de cada pedido. Utiliza los filtros inferiores para organizar la vista."
                : "Seleccioná cualquiera de los pedidos para conocer el detalle del mismo."
            }
            showInicioButton={true}
          /> */}

          <Flex
            justifyContent="space-between"
            alignItems="center"
            p={2}
            width="100%"
            // direction={"column"}
          >
            <HStack spacing={2}>
              <Text>
                {filteredAndSortedPedidos.length}
                {selectedStatus === "todos"
                  ? " pedidos en total"
                  : ` pedidos ${selectedStatus.replace("_", " ")}`}
              </Text>
              {user?.role === "Admin" && (
                <Tooltip
                  label={`Última actualización: ${new Date(
                    lastUpdateTime
                  ).toLocaleTimeString()}`}
                >
                  <HStack spacing={1} color="gray.500" fontSize="sm">
                    <Clock size={14} />
                    <Text>Hace {timeSinceUpdate}</Text>
                  </HStack>
                </Tooltip>
              )}
            </HStack>
            {/* {user?.role === "Admin" && (
              <Button
                onClick={togglePolling}
                leftIcon={
                  pollingEnabled ? <Pause size={16} /> : <Play size={16} />
                }
                size="sm"
                colorScheme={pollingEnabled ? "orange" : "green"}
              >
                {pollingEnabled
                  ? "Detener actualización"
                  : "Activar actualización"}
              </Button>
            )} */}
            <IconButton
              icon={<RotateCcw />}
              onClick={handleRefresh}
              aria-label="Refresh orders"
              isLoading={isRefreshing}
              disabled={isRefreshing}
            />
          </Flex>

          {loadingError && (
            <ErrorState
              message={loadingError}
              onRetry={handleRefresh}
              onLogout={() => (window.location.href = "/login")}
            />
          )}

          {!loadingError && filteredAndSortedPedidos.length === 0 && (
            <NoOrdersState />
          )}

          {filteredAndSortedPedidos.map(
            (pedido) =>
              pedido?.attributes?.pedido_articulos && (
                <OrderBox
                  key={pedido.id}
                  date={formatDate(pedido.attributes.createdAt)}
                  orderNumber={pedido.id}
                  total={pedido.attributes.total}
                  items={pedido.attributes.pedido_articulos.data || []}
                  estado={pedido.attributes.estado}
                />
              )
          )}
        </VStack>

        {user?.role === "Admin" && (
          <HStack spacing={2} width="100%" sx={styles.container}>
            {[
              { icon: ClipboardList, label: "Pendientes", value: "pendiente" },
              { icon: Loader, label: "En Proceso", value: "en_proceso" },
              { icon: CheckCircle, label: "Finalizados", value: "finalizado" },
              { icon: XCircle, label: "Cancelados", value: "cancelado" },
              { icon: ClipboardList, label: "Todos", value: "todos" },
            ].map(({ icon: Icon, label, value }) => (
              <Button
                key={value}
                height="44px"
                display="flex"
                flexDir="column"
                leftIcon={<Icon size={16} />}
                fontSize="12px"
                onClick={() => handleStatusChange(value)}
                colorScheme={selectedStatus === value ? "blue" : "gray"}
              >
                {label}
              </Button>
            ))}
          </HStack>
        )}
      </Box>
    </ChakraProvider>
  );
}

const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    zIndex: 1000,
    boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
  },
};
