import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Landing from "./Landing/Landing";
import SimpleCard from "./Perfil/Autenticacion/LogIn";
import SignupCard from "./Perfil/Autenticacion/SignUp";
import Exito from "./Results/Exito";
import Error from "./Results/Error";
import NotFound from "./Results/NotFound";
import UserProfile from "./Perfil/Perfil";
import EditUser from "./Results/EditUser";
import Ayuda from "./Results/Ayuda";
import Layout from "./Results/layout";
import CatalogoFull from "./Catalogo/CatalogoFull";
import PersonalDataForm from "./Perfil/PersonalData";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchValor } from "./Redux/Slice";
import DetalleProducto from "./Catalogo/ProductDetail";
import Pedidos from "./Perfil/Pedidos";
import Carrito from "./Carrito/Carrito";
import PedidoDetalle from "./Perfil/PedidoDetalle";
import MobileAppBanner from "./Results/MobileAppBanner";
import WhatsAppButton from "./Results/ButtonWssp";
import LoginCard from "./Perfil/Autenticacion/LogIn";
import GoogleCallback from "./Perfil/Autenticacion/GoogleCallback";
import PrivacyPolicy from "./Perfil/Autenticacion/PrivacyPolicy";
import EmailConfirmation from "./Perfil/Autenticacion/EmailConfirmation";
import { Flex, Spinner } from "@chakra-ui/react";


// Componente wrapper para las categorías
const CategoryWrapper = ({ categories }) => {
  const location = useLocation();
  const { status } = useSelector((state) => state);
  const currentPath = location.pathname.substring(1);

  const formatUrl = (name) => {
    return name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/ /g, '-');
  };

  if (status === 'loading' || !categories.length) {
    return (
      <Flex 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
        width="100%"
      >
        <Spinner 
          size="xl"
          speed="0.65s"
          color="#CA0017"
        />
      </Flex>
    );
  }

  const matchingCategory = categories.find(cat => 
    formatUrl(cat.nombre) === currentPath
  );

  if (!matchingCategory) {
    return <Navigate to="/notfound" replace />;
  }

  return <Layout child={<CatalogoFull categori={matchingCategory} />} />;
};

function App() {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);
  
  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchValor());
  }, [dispatch]);

  return (
    <Router>
      <div className="App">
        <MobileAppBanner/>
        <WhatsAppButton phoneNumber="+5491140680612" />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Login" element={<LoginCard />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path="/confirmar-email" element={<EmailConfirmation />} />
          <Route path="/Signup" element={<SignupCard />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Exito/:id" element={<Exito />} />
          <Route path="/error" element={<Error />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/UserProfile" element={<UserProfile />} />
          <Route path="/Pedidos" element={<Pedidos />} />
          <Route path="/Carrito" element={<Carrito />} />
          <Route path="/Pedidos/:id?" element={<PedidoDetalle />} />
          <Route path="/EditUser" element={<EditUser />} />
          <Route path="/Ayuda" element={<Layout child={<Ayuda />} />} />
          <Route path="/ProdDetalle/:id" element={<DetalleProducto />} />
          <Route
            path="/CatFull"
            element={<Layout child={<CatalogoFull />} />}
          />
          <Route path="/PersonalData" element={<PersonalDataForm />} />
          
          {/* Rutas dinámicas para categorías con URLs formateadas */}
          {Array.isArray(categories) && categories.map(categoria => {
            const categoryUrl = categoria.nombre
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/ /g, '-');
            
            return (
              <Route 
                key={categoria.id}
                path={`/${categoryUrl}`} 
                element={<CategoryWrapper categories={categories} />}
              />
            );
          })}
          
          <Route path="*" element={<Navigate to="/notfound" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;