import React, { useState, useEffect } from "react";
import ResponsiveNav from "../Landing/ResponsiveNav";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";


function Layout({child}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={styles.container}>
      <ResponsiveNav showBackButton={true}/>
      <div style={styles.container2}>
      {child}
      </div>
    </div>
  );
}

export default Layout;

const styles = {
  container: { paddingBottom: "60px",
    minHeight:"100vh",
    maxHeight:"100%"
   },
  container2: {
    display: "flex",
    flexDirection: "column",
    paddingBottom:"1rem",
    overflow:"hidden"

  },
  hero: {
    display: "flex",
    alignItems: "center",
    overflow:"hidden"
  },
};
